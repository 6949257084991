<template>
  <div id="uploaddocs">
      <form enctype="multipart/form-data" @submit.prevent="onSubmit()" v-if="isInitial || isSaving">
            <div class="autocomplete">
                <label class="label" for="staffid">Staff id</label>
                <input class="input" type="text" id="staffid" name="staffid" placeholder="Start Typing Full Name..." maxlength="40" v-model="staffid" @input="staffIDonChange">
                <ul v-show="isOpen" class="autocomplete-results">
                    <li v-for="(result, i) in results" :key="i" @click="setStaffIDResults(result)" class="autocomplete-result box">
                        StaffID: {{ result.user_id }} for {{ result.name}} {{ result.surname}}
                    </li>
                </ul>
            </div>
            <!-- <span>Staffid: {{ staffid}}</span> -->
            <div class="box">
              <h2 v-if="doclist" class="block">Tax Year {{ year }} Files uploaded for {{ staffid }}</h2>
              <ul v-if="doclist">
                <li class="has-text-centered level-item block" v-for="doc in doclist" v-bind:key="doc.doc_id">
                    {{ doc.remark }} - {{ doc.doc_id}} uploaded {{ doc.time}} by {{ doc.handledby}}
                    <input class="button is-small" type="button" id={{doc.doc_id}} @click="getFile(doc.link,doc.remark)" value="Download" />
                    <button type="button" class="delete is-medium" @click="delFileConfirm(doc)" />
                </li>
              </ul>
              <div v-else class="notification">
                  No files have been uploaded yet for {{ staffid}} for Tax Year {{year}}.
              </div>
            </div>
            <fieldset data-type="horizontal" class="control">
                <legend class="label">Select the type of document being uploaded:</legend>
                <label for="doctype">T4</label>
                <input type="radio" id="T4" value="T4" name="doctype" v-model="doctype">
                <label for="doctype">T2200S</label>
                <input type="radio" id="T2200S" value="T2200S" name="doctype" v-model="doctype">
                <label for="doctype">T2200</label>
                <input type="radio" id="T2200" value="T2200" name="doctype" v-model="doctype">
            </fieldset>
            <label for="filetoupload">Select File:</label>
            <div class="dropbox">
                <input type="file" id="filetoupload" :name="uploadFieldName" @change="filesChange($event.target.name, $event.target.files);" class="input-file" />
                <p v-if="isInitial">Drag your PDF file here to begin<br> or click to browse</p>
                <p v-if="isSaving">Upload {{ file.name }} for {{ staffid }}</p>
            </div>
            <input class="button is-primary" type="submit">
      </form>
      <div class="notification is-success" v-if="isSuccess">
        <h2>Uploaded {{ file.name }} successfully for {{ staffid }}.</h2>
        
          <a href="javascript:void(0)" class="button" @click="uploadReset()">Upload Another Document</a>
        
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>Uploaded failed.</h2>
        <p>
          <a href="javascript:void(0)" @click="uploadReset()">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div>
      <div class="modal" :class="{'is-active': confirmDelete}">
        <div class="modal-background">
          <div class="model-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Confirm Delete</p>
              <button class="delete" @click="cancelDelFile()" aria-label="close"></button>
            </header>
            <section class="modal-card-body">
              Delete {{ selectedDelFile.remark }} for {{ staffid }}?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-success" @click="delFile()">Yes</button>
              <button class="button" @click="cancelDelFile()">Cancel</button>
            </footer>
          </div>
          
        </div>
      </div>
      <!-- <span>Selected doctype: {{ doctype}}</span>
      <label>Press to get userIDs</label><input type="button" @click="getUserID()" value="Get Users" />
    
      <ul v-if="userids">
          <li v-for="userid in userids" v-bind:key="userid.user_id">
              <p>{{userid.user_id}} {{userid.name}} {{userid.surname}}</p>
          </li>
      </ul> -->
  </div>
</template>

<script>
import axios from 'axios';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
// const POST_URL = "http://localhost/taxdocphp/fileUpload.php";
// const POST_URL2 = "http://localhost/taxdocphp/fileDel.php";
// const GET_URL = "http://localhost/taxdocphp/getUsers.php";
// const GET_URL2 = "http://localhost/taxdocphp/fileDownload.php";
const POST_URL = "https://taxdocphp.brontecollege.ca/fileUpload.php";
const POST_URL2 = "https://taxdocphp.brontecollege.ca/fileDel.php";
const GET_URL = "https://taxdocphp.brontecollege.ca/getUsers.php";
const GET_URL2 = "https://taxdocphp.brontecollege.ca/fileDownload.php";

export default {
    

    data() {
        return {
            staffid: '',
            doctype: 'T4',
            userids: null,
            isOpen: false,
            search: '',
            results: [],
            file: null,
            currentStatus: STATUS_INITIAL,
            uploadFieldName: 'filetoupload',
            uploadError: null,
            uploadedFiles: [],
            doclist: null,
            confirmDelete: false,
            selectedDelFile: []
        }
    },
    props: {
      //TODO implement if year changes, document search should change
        year: {
            type: String,
            required: true
        }
    },
    watch: {
        year: function() {
            //console.log(`Watch Year:${this.year}`);
            this.doclist = null;
            this.getUserDocs();
        }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
    },
    mounted() {
        this.getUserID()
    },
    methods: {
        uploadReset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
            this.file = null;
            this.staffid = null;
            this.doclist = null;
            this.selectedDelFile = [];
            this.confirmDelete = false;
        },
        filesChange(fieldName, fileList) {
            // handle file changes
            //const formData = new FormData();
            //console.log(fieldName);

            // append the files to FormData
            Array
            .from(Array(fileList.length).keys())
            .map(x => {
                //formData.append(fieldName, fileList[x], fileList[x].name);
                //console.log(fileList[x].name);
                this.file = fileList [x];

            });
            //this.file = fileList
            this.currentStatus = STATUS_SAVING;
        },
        onSubmit() {
            //console.log("onSubmit");
            if (this.staffid === '' || this.doctype === null || this.file === null) {
                alert('Please fill in all fields');
                return
            }
            let self = this;
            self.currentStatus = STATUS_SAVING;
            // console.log(this.staffid);
            // console.log(this.doctype);
            //  console.log(this.year);
            // console.log(this.file);
            let formData = new FormData ();
            formData.append('staffid',this.staffid);
            formData.append('doctype',this.doctype);
            formData.append('year',this.year);
            formData.append('filetoupload',this.file);
            formData.append('uploadedby',this.$store.state.staffID);
            axios.post(POST_URL, formData)
            .then(function(response) {
                console.log(response.data.message);
                self.uploadedFiles = [self.file.name];
                self.currentStatus = STATUS_SUCCESS;
            })
            .catch(function (error) {
                //console.log(error);
                self.uploadError = error.response;
                self.currentStatus = STATUS_FAILED;
            });
        },
        staffIDonChange() {
            this.isOpen = true;
            this.filterStaffIDResults();
            //this.getUserDocs();
        },
        filterStaffIDResults() {
            this.results = this.userids.filter(userids => userids.name.toLowerCase().indexOf(this.staffid.toLowerCase()) > -1);
        },
        setStaffIDResults(result) {
            this.isOpen = false;
            this.staffid = result.user_id;
            this.getUserDocs();
        },
        getFile(docRequested,docName) {
            // console.log(docRequested);
            let self = this;
            axios.get(GET_URL2, {
                params: {
                    staffid: this.staffid,
                    year: self.year,
                    action: 'Download',
                    doclink: docRequested
                },
                responseType: 'blob'
            })
            .then(function (response) {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fileLink = document.createElement('a');

                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', docName);
                     document.body.appendChild(fileLink);

                     fileLink.click(); 
                //console.log(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        delFileConfirm(docRequested) {
          // console.log("delFileConfirm");
          // console.log(docRequested);
          
          this.selectedDelFile = docRequested;
          // console.log(this.selectedDelFile.link);
          // console.log(this.selectedDelFile.remark);
          this.confirmDelete = true;   
        },
        cancelDelFile() {
          this.confirmDelete = false;
        },
        delFile() {
          // console.log(this.selectedDelFile.link);
          let self = this;
          let formData = new FormData ();
          formData.append('doclink',this.selectedDelFile.link);
          formData.append('staffid',this.staffid);
          formData.append('doc_id',this.selectedDelFile.doc_id);
          formData.append('remark',this.selectedDelFile.remark);
          formData.append('deletedby',this.$store.state.staffID);
          axios.post(POST_URL2, formData)
          .then(function(response) {
              // console.log(response.data.message);  
              if (response.data.status == 0) {
                self.confirmDelete = false;
              }
              self.getUserDocs();
          })
          .catch(function (error) {
              console.log(error);
          });
        },
        getUserDocs() {
          let self = this;
          this.doclist = null;
            axios.get(GET_URL2, {
                params: {
                    staffid: this.staffid,
                    year: self.year,
                    action: 'ViewAll'
                }
            })
            .then(function (response) {
                // Should handle the response
                //console.log(response);
                if (response.data.status == 0) {
                    self.doclist = response.data.response;
                } else {
                    self.doclist = null;
                }
                //  console.log(response.data.status);
                //  console.log(response.data.message);
                //  console.log(response.data.response);

            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getUserID: function() {
            let self = this;
            axios.get(GET_URL)
            .then(function (response) {
                // Should handle the response
                self.userids = response.data;
                //console.log(response.data);

            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>

<style>
.autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }

  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>