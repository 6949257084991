<template>
  <div id="uploaddocs">
    <h1 class="title">Upload Tax Documents</h1>
    <select-year v-model="thisYear" />
    <upload-documents-component :year="thisYear" />
  </div>
</template>

<script>
import SelectYear from "@/components/SelectYear.vue"
import UploadDocumentsComponent from '../components/UploadDocumentsComponent.vue'

export default {
    data() {
      return {
        thisYear: '2022'
      }
    },
    components: {
      SelectYear, UploadDocumentsComponent
    }
}
</script>
